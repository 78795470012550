<template>
  <div class="home-container" v-if="pageData">
    <div class="grid">
      <div class="center-flex">
        <h1>{{ pageData.title }}</h1>
        <h4>{{ pageData.description }}</h4>
      </div>
    </div>
  </div>
</template>

<script>
import { HOME_QUERY } from "@/gql-operations/allGqlOperations";

export default {
  name: "home",
  data() {
    return {
      pageData: [],
    };
  },
  apollo: {
    getData: {
      query: HOME_QUERY,
      update(data) {
        this.pageData = data.homePage.nodes[0].homePageFields.content;
      },
    },
  },
};
</script>

<style scoped lang="scss">
.home-container {
  .grid {
    @include right-panel-grid;

    .center-flex {
      grid-column: 2 / span 7;
    }
  }
}

@media screen and (max-width: $small-screen) {
  .uxstep-container {
    .grid {
      .centered-flex {
        grid-column: 1 / span 9;
      }
    }
  }
}
</style>
